import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AboutImg from '../../static/img/about-img.jpg'
import IntroImg from '../../static/img/intro.jpg'

const IndexPage = () => (
  <Layout>
       <section id="intro" style={{backgroundImage:`url(${IntroImg})`}}>

        <div className="intro-content">
          <h2>Refer and <span>Recapsure</span></h2>
          <h5>Grow your independent book of business!</h5>
          <div>
            <a href="#portfolio" className="btn-projects scrollto">Get Started</a>
          </div>
        </div>

      </section>

      <main id="main">

       <section id="for-agents" className="wow fadeInUp">
         <div className="container">
              <div className="section-header">
               <h2>For Agents</h2>
               <p>First and foremost, we are an insurace agency dedicated to customer service. We pride ourselves on delivering the same quality of service that you come to expect from your agency. With over 20 years of experience as insurance agents we understand the importance of maintaining an honesty, trust-worthy relationship with the policy holder.</p>
             </div>
           <div className="row">
             <div className="col-lg-6 about-img">
               <img src={AboutImg} alt="" />
             </div>

             <div className="col-lg-6 content">
               <h2>Agent Referral Program</h2>
               <h3>Exclusive program for captive agents to help them grow their independent book of business.</h3>

               <ul>
                 <li><FontAwesomeIcon icon="check-circle"></FontAwesomeIcon> Earn $25 for each policy referred.</li>
                 <li><FontAwesomeIcon icon="check-circle"></FontAwesomeIcon> Track your earnings in our dashboard and cash out any time.</li>
                 <li><FontAwesomeIcon icon="check-circle"></FontAwesomeIcon> Recapsure will never cross-sell your referrals.</li>
                 <li><FontAwesomeIcon icon="check-circle"></FontAwesomeIcon> Our goal is to be your choice for hard to place policies.</li>
                 <li><FontAwesomeIcon icon="check-circle"></FontAwesomeIcon> Receive renewal and policy alerts through a personalized dashboard.</li>
               </ul>

             </div>
           </div>

         </div>
       </section>
       <section id="call-to-action" className="wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 text-center text-lg-left">
              <h3 className="cta-title">Join Today!</h3>
              <p className="cta-text"> Take a few minutes to sign up for our agent referral program and receive immediate access to your personalized dashboard. Start referring policies and growing your independent book of business today!</p>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              <a className="cta-btn align-middle" href="#">Get Started</a>
            </div>
          </div>

        </div>
      </section>
       <section id="insurance-products">
        <div className="container">
          <div className="section-header">
            <h2>Insurance Products</h2>
            <p>As an independent insurance agency we can shop multiple insurance companies to get you the best policy at an afforable price. Our team of highly qualified agents work tirelessly to provide you and your family with impeccable service.</p>
          </div>

          <div className="row">

            <div className="col-lg-6">
              <div className="box wow fadeInLeft">
                <div className="icon"><FontAwesomeIcon icon="car"></FontAwesomeIcon></div>
                <h4 className="title"><a href="">Auto</a></h4>
                <p className="description">Collision, Comprehensive, Liability, Medical, Uninsured/Underinsured Motorist</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="box wow fadeInRight">
                <div className="icon"><FontAwesomeIcon icon="home"></FontAwesomeIcon></div>
                <h4 className="title"><a href="">Home/Renters</a></h4>
                <p className="description">Homeowners, Condos, Rental Properties, Renters, Manufactured Homes, Farm or Ranch</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                <div className="icon"><FontAwesomeIcon icon="motorcycle"></FontAwesomeIcon></div>
                <h4 className="title"><a href="">Motorcycle</a></h4>
                <p className="description">Factory-built motorcycles, Motor scooters, Minibikes, Custom motorcycles, Classic motorcycles, Trail bikes</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="box wow fadeInRight" data-wow-delay="0.2s">
                <div className="icon"><FontAwesomeIcon icon="ship"></FontAwesomeIcon></div>
                <h4 className="title"><a href="">Boat</a></h4>
                <p className="description">Runabout/Sport Boats, Cruiser/Yachts, Sailboats, Bass Boats, Jet Skis, Houseboats, Kayak/Canoes</p>
              </div>
            </div>

          </div>

        </div>
      </section>
      <section id="call-to-action" className="cta-alt wow fadeInUp">
       <div className="container">
         <div className="row">
           <div className="col-lg-9 text-center text-lg-left">
            <h3 className="cta-title">Get a Quote</h3>
            <p className="cta-text"> Our team of highly-qualified agents are ready to shop multiple insurance companies to get you the most coverage at an afforable price.</p>
           </div>
           <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" href="#">Get Started</a>
           </div>
         </div>

       </div>
     </section>
      <section id="team" className="wow fadeInUp">
       <div className="container">
         <div className="section-header">
           <h2>Our Team</h2>
         </div>
         <div className="row">
           <div className="col-md-4">
             <div className="member">
               <div className="pic"></div>
               <div className="details">
                 <h4>Jack Arnold</h4>
                 <span>Agent</span>
               </div>
             </div>
           </div>

           <div className="col-md-4">
             <div className="member">
               <div className="pic"></div>
               <div className="details">
                 <h4>Jessica Euering</h4>
                 <span>Marketing Specialist</span>
               </div>
             </div>
           </div>

           <div className="col-md-4">
             <div className="member">
               <div className="pic"></div>
               <div className="details">
                 <h4>Heather McMahon</h4>
                 <span>Client Appreciation</span>
               </div>
             </div>
           </div>
         </div>

       </div>
     </section>

     <section id="contact" className="wow fadeInUp">
      <div className="container">
        <div className="section-header">
          <h2>Contact Us</h2>
          <p>Sed tamen tempor magna labore dolore dolor sint tempor duis magna elit veniam aliqua esse amet veniam enim export quid quid veniam aliqua eram noster malis nulla duis fugiat culpa esse aute nulla ipsum velit export irure minim illum fore</p>
        </div>

        <div className="row contact-info">

          <div className="col-md-4">
            <div className="contact-address">
              <FontAwesomeIcon icon="map-marker-alt"></FontAwesomeIcon>
              <h3>Address</h3>
              <address>A108 Adam Street, NY 535022, USA</address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-phone">
              <FontAwesomeIcon icon="phone"></FontAwesomeIcon>
              <h3>Phone Number</h3>
              <p><a href="tel:+155895548855">+1 5589 55488 55</a></p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <FontAwesomeIcon icon="envelope"></FontAwesomeIcon>
              <h3>Email</h3>
              <p><a href="mailto:info@example.com">info@example.com</a></p>
            </div>
          </div>

        </div>
      </div>
      <div className="container">
        <div className="form">
          <form action="" method="post" role="form" className="contactForm">
            <div className="form-row">
              <div className="form-group col-md-6">
                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div className="validation"></div>
              </div>
              <div className="form-group col-md-6">
                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div className="validation"></div>
              </div>
            </div>
            <div className="form-group">
              <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
              <div className="validation"></div>
            </div>
            <div className="form-group">
              <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
              <div className="validation"></div>
            </div>
            <div className="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div>
</section>
 </main>
 <footer id="footer">
   <div className="container">
     <div className="copyright">
       &copy; Copyright <strong>Recapsure LLC</strong>. All Rights Reserved
     </div>
     <div className="credits">

     </div>
   </div>
 </footer>
 <a href="#" className="back-to-top"><FontAwesomeIcon icon="chevron-up"></FontAwesomeIcon></a>
  </Layout>
)

export default IndexPage
